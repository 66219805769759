import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"
import { PageSeo } from "../components/PageSeo"
import Layout from "../components/Layout"
import Banner from "../components/Banner"

const JobOpeningsVacancTemplate = ({ data }) => {
  const joblist = data.allMarkdownRemark.edges
  const { markdownRemark: markup } = data
  const { banner } = markup.frontmatter

  return (
    <Layout>
      <Banner data={banner} />
      <section className="JobOpeningsVacancTemplate container py-3 py-lg-5">
        <div className="row">
        <div className="col-12 mb-3">
        <div className="card">
        <div className="card-body">
          <h6>Thank you for your interest in Credence Analytics</h6>
          <p>Unfortunately, at this time, we don't have any job vacancies that would match your profile and interests.</p>


          </div>
          </div>
          <h6 className="section_heading text-center pt-3">Jobs @ Credence Analytics</h6>
        </div>
          {joblist.map(({ node }, index) => (
              <ItemTemplate
              key={`joblist_${index}`}
              name={node.frontmatter.name}
              item={node.frontmatter.jobDetails}
              html={node.html}
              url={node.fields.slug}
            />
          ))}
        </div>
      </section>
    </Layout>
  )
}

const ItemTemplate = ({ name, item, url }) => {
  return (
    <div className="col-12 mb-3 ">
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title text-uppercase text-primary">{name}</h5>
          {
           item.vacancies  &&
              <div>
        
          <p className="">
            <strong>Job Location: </strong>

            <ReactMarkdown children={item.region || "Mumbai"}></ReactMarkdown>

            <strong>Min. Experience:</strong>

            <ReactMarkdown children={item.experience || "Any"}></ReactMarkdown>
          </p>

          <div>
            <Link className="btn btn-sm btn-outline-primary" to={url}>
              Read More
            </Link>
          </div>
          </div>
            }
        </div>
      </div>
    </div>
  )
}

ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    jobDetails: PropTypes.object,
  }),
}

export const JobOpeningsVacancPageQuery = graphql`
  query JobOpeningsVacancPageQuery($id: String!) {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sequence] }
      filter: {
        frontmatter: { templateKey: { eq: "job-openings-vacancy-slug" } }
      }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            name
            sequence
            jobDetails {
              vacancies
              region
              experience
            }
          }
        }
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default JobOpeningsVacancTemplate
export const Head = () => (
  <PageSeo
    title="Job Openings | Ranked #1 Investment Management Software Solution Provider"
    description="At Credence, we operate in an open and liberal work culture. Responsibility, ownership and initiative-taking are our main drivers at work and play."
    keywords="Trainee Assistant Consultant Software Implementation, Assistant Consultant Software Developer Support, jobs, openings, vacancy, careers, internships, interns, freelancer"
  />
)
